<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Email") }}</label>
          <vs-input v-model="AppUserSearch.Email" class="w-full" name="Email" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("FullName") }}</label>
          <vs-input v-model="AppUserSearch.FullName" class="w-full" name="FullName" />
        </div>

        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("PhoneNumber") }}</label>
          <vs-input v-model="AppUserSearch.PhoneNumber" class="w-full" name="PhoneNumber" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("IsActive") }}</label>
          <vs-checkbox
                v-model="AppUserSearch.IsActive"
                :value="true"
              ></vs-checkbox>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
            class="my-6 rounded-sm"
            @click="AppUserSearchFirst"
            color="warning"
            >{{ $t("Search") }}</vs-button
          >
        </div>
      </div>
    </vx-card>
    <div id="data-list-list-view" class="data-list-container">
      <vs-table
        ref="table"
        id="Scroll"
        pagination
        :max-items="itemsPerPage"
        search
        :data="AppUsers"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
          <!-- <div class="vx-row">
            <vs-button
              type="border"
              class="ml-5"
              icon-pack="feather"
              icon="icon-plus"
              @click="addNewData"
              >{{ $t("AddNew") }}</vs-button
            >
          </div> -->
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
            <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
            >
              <span class="mr-2"
                >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                {{
                  AppUsers.length - currentPage * itemsPerPage > 0
                    ? currentPage * itemsPerPage
                    : AppUsers.length
                }}
                of {{ AppUsers.length }}</span
              >
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="itemsPerPage = 4">
                <span>4</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 10">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 15">
                <span>15</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="itemsPerPage = 20">
                <span>20</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <template slot="thead">
          <vs-th sort-key="Name">{{ $t("NameAr") }}</vs-th>
          <vs-th sort-key="Name">{{ $t("NameEn") }}</vs-th>
          <vs-th sort-key="Email">{{ $t("Email") }}</vs-th>
          <vs-th> {{ $t("Action") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data"
              @click="editData(tr)"
            >
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.ApplicationUser.FullNameAr }}
                </p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.ApplicationUser.FullNameEn }}
                </p>
              </vs-td>
               <vs-td>
                <p class="product-name font-medium truncate">
                  {{ tr.ApplicationUser.Email }}
                </p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                  <router-link
                    :to="{ name: 'AppUser/Add-Edit', params: { ID: tr.ID } }"
                    svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  >
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  /></router-link>
                  <feather-icon
                    icon="TrashIcon"
                    svgClasses="w-10 h-5 hover:text-primary stroke-current"
                    @click.stop="openConfirm(tr)"
                  />
                  <feather-icon
                  icon="LockIcon"
                  svgClasses="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="resetPassword(tr)"
                />

              </vs-td>
            </tr>
          </tbody>
        </template>
      </vs-table>
    </div>
    <data-view-sidebar
        :isSidebarActive="addNewDataSidebar"
        @closeSidebar="toggleDataSidebar"
        :data="sidebarData"
        :isResetPassword="isResetPassword"
      />
  </div>
</template>
<script>
import moduleAppUser from "@/store/user/appuser/moduleAppUser.js";
import { domain } from "@/gloabelConstant.js";
import DataViewSidebar from "@/views/user/DataViewSidebar.vue";

export default {
  components: {
    DataViewSidebar
  },

  data() {
    return {
      isResetPassword: false,
      addNewDataSidebar: false,
      search: {

        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
        Name: "",
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    AppUserSearch() {
      debugger;
      return this.$store.state.AppUserList.search;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    AppUsers() {
      return this.$store.state.AppUserList.AppUsers;
    },
  },

  methods: {
    resetPassword(data) {
      this.sidebarData = data;
      this.isResetPassword = true;

      this.toggleDataSidebar(true);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    AppUserDataSearch() {
      debugger;
      this.$store
        .dispatch("AppUserList/SearchAppUser", this.AppUserSearch)
        .then((res) => {
          if (
            document.documentElement.scrollHeight <= window.innerHeight &&
            res.data.Data.length >= this.search.PageSize
          ) {
            this.AppUserDataSearch();
          }
        });
    },
    AppUserSearchFirst() {
      debugger;
      this.search.PageNumber = 1;
      this.AppUserDataSearch();
    },
    editData(data) {
      debugger;
      this.$router.push({ name: "AppUser-Edit", params: { ID: data.ID } });
    },

    openConfirm(data) {
      debugger;
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
    deleteData(data) {
      debugger;
      this.$store.dispatch("AppUserList/DeleteAppUser", data).catch((err) => {
        console.error(err);
      });
    },
  },
  created() {
    this.$vs.loading();
    if (!moduleAppUser.isRegistered) {
      this.$store.registerModule("AppUserList", moduleAppUser);
      moduleAppUser.isRegistered = true;
    }
    this.$store
      .dispatch("AppUserList/fetchDataListItems")
      .then(() => {
        this.$vs.loading.close();
      })
      .catch(() => {
        this.$vs.loading.close();
      });
  },
  mounted() {
    this.isMounted = true;
    this.$store.commit("AppUserList/SET_AppUsers", []);
    this.AppUserSearch.IsActive = true
    this.AppUserSearchFirst();
  },
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
