/*=========================================================================================
  File Name: mouduleAppUserActions.js
  Description: AppUser Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("api/AppUser/AddAppUser", item)
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM",
            Object.assign(item, { ID: response.data.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/AppUser/GetAll")
        .then(response => {
          resolve(response);
          commit("SET_AppUsers", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SearchAppUser({ commit }, search) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/AppUser/SearchAppUser", search)
        .then(response => {
          resolve(response);
          commit("SET_AppUsers", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem(context, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .post("api/AppUser/UpdateAppUser", item)
        .then(response => {
          resolve(response);
         // commit("UPDATE_AppUser", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID(context, itemid) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("api/AppUser/GetAppUser?ID=" + itemid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteAppUser({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/AppUser/DeleteAppUser?ID=" + item.ID)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.ID);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
