export default {
  ADD_ITEM(state, item) {
    state.AppUsers.unshift(item);
  },
  SET_AppUsers(state, AppUsers) {
    state.AppUsers = AppUsers;
  },
  UPDATE_AppUser(state, AppUser) {
    const AppUserIndex = state.AppUsers.findIndex(p => p.ID == AppUser.ID);
    if (AppUserIndex != -1) {
      Object.assign(state.AppUsers[AppUserIndex], AppUser);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.AppUsers.findIndex(p => p.ID == itemId);
    state.AppUsers.splice(ItemIndex, 1);
  }
};
